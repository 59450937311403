// eslint-disable-next-line no-unused-vars
var dynamicContent = {};
import {
  resetDocumentStates,
  updateMiniCartCount,
  updateMiniCartHtml,
} from './components/wc/wcHelper';
import { initNotices } from './components/noticeToast';
import { applyScrollableIndicator } from './components/UITools';

import Cookies from 'js-cookie';

//TEMP
//import {onCLS, onINP, onLCP, onTTFB} from 'web-vitals';
//onCLS(console.log);
//onINP(console.log);
//onLCP(console.log);
//onTTFB(console.log);
export function getDynamicContent() {
  if (dynamicContent === {}) {
    console.log('dynamic content is not set!');
  }
  return dynamicContent;
}

export function initDynamicContent() {
  let jqxhr = requestDynamicContent();
  jqxhr.done(function (response) {
    updateThemeVariablesWithNewerData(response.data);
    dynamicContent = response.data;

    triggerUpdates();
  });
}

export function triggerUpdates() {
  // This is for async ajax.
  updateMiniCartHtml(window.theme.miniCartHtml);
  updateMiniCartCount(window.theme.miniCartCount);

  resetDocumentStates(); // Do it after updating mini cart html.
  applyScrollableIndicator('.js-mini-cart-scroll-indicator');
  initNotices();
}

let updateThemeVariablesWithNewerData = function (data) {
  window.theme.pendingNotices = data.pendingNotices;
  window.theme.currentSearchCountryCode = data.currentSearchCountryCode;
  window.theme.miniCartHtml = data.miniCartHtml;
  window.theme.miniCartCount = data.miniCartCount;
};
let requestDynamicContent = function () {
  Cookies.set('pll_language', window.theme.currentLanguage);
  let data = {
    action: 'get_dynamic_content',
    company_id: window.theme.companyId,
    company_hash: window.theme.companyHash,
    current_language: window.theme.currentLanguage,
  };

  let headers = {
    'Cf-Ipcountry': window.theme.cfCountryCode,
  };

  return jQuery.ajax({
    // eslint-disable-next-line no-unused-vars
    type: 'GET',
    url: window.theme.ajaxUrl,
    headers: headers,
    data: data,
    async: true,
    error: function (xhr, status, error) {
      console.log('unexpected error while getting dynamic content:');
      console.log(error);
    },
  });
};

updateMiniCartCount(); // To set it to last know value
initDynamicContent();
