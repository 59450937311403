/* This JS is to be NON-cached-defered-delayed */
import './cacheUpdater.js';
import { initMobileCompanyCtaClick } from './components/companyPage/mobileShowDocuments';

import {
  handleMiniCartOpenCloseClick,
  initLogoContainer,
} from './components/UITools.js';

$(document).ready(function () {
  /** iODoubleTapFix
   *
   */
  if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
    $(document).on('touchstart', function () {});
  }

  initMobileCompanyCtaClick();
  handleMiniCartOpenCloseClick();
  initLogoContainer();
});
